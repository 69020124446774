export function countries() {
  return [
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AE', name: 'United Arab Emirates' },
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AG', name: 'Antigua and Barbuda' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AL', name: 'Albania' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AO', name: 'Angola' },
    { id: 'AP', name: 'Asia/Pacific Region' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AT', name: 'Austria' },
    { id: 'AU', name: 'Australia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AX', name: 'Aland Islands' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BA', name: 'Bosnia and Herzegovina' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BI', name: 'Burundi' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BL', name: 'Saint Bartelemey' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BO', name: 'Bolivia' },
    { id: 'BQ', name: 'Bonaire, Saint Eustatius and Saba' },
    { id: 'BR', name: 'Brazil' },
    { id: 'BS', name: 'Bahamas' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BZ', name: 'Belize' },
    { id: 'CA', name: 'Canada' },
    { id: 'CC', name: 'Cocos (Keeling) Islands' },
    { id: 'CD', name: 'Congo, The Democratic Republic of the' },
    { id: 'CF', name: 'Central African Republic' },
    { id: 'CG', name: 'Congo' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'CI', name: "Cote d'Ivoire" },
    { id: 'CK', name: 'Cook Islands' },
    { id: 'CL', name: 'Chile' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CN', name: 'China' },
    { id: 'CO', name: 'Colombia' },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CV', name: 'Cape Verde' },
    { id: 'CW', name: 'Curacao' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czech Republic' },
    { id: 'DE', name: 'Germany' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EE', name: 'Estonia' },
    { id: 'EG', name: 'Egypt' },
    { id: 'EH', name: 'Western Sahara' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'ES', name: 'Spain' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'EU', name: 'Europe' },
    { id: 'FI', name: 'Finland' },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)' },
    { id: 'FM', name: 'Micronesia, Federated States of' },
    { id: 'FO', name: 'Faroe Islands' },
    { id: 'FR', name: 'France' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GB', name: 'United Kingdom' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GE', name: 'Georgia' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GM', name: 'Gambia' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'GR', name: 'Greece' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GU', name: 'Guam' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HM', name: 'Heard Island and McDonald Islands' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HR', name: 'Croatia' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HU', name: 'Hungary' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IL', name: 'Israel' },
    { id: 'IM', name: 'Isle of Man' },
    { id: 'IN', name: 'India' },
    { id: 'IO', name: 'British Indian Ocean Territory' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IR', name: 'Iran, Islamic Republic of' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IT', name: 'Italy' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JO', name: 'Jordan' },
    { id: 'JP', name: 'Japan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KM', name: 'Comoros' },
    { id: 'KN', name: 'Saint Kitts and Nevis' },
    { id: 'KP', name: "Korea, Democratic People's Republic of" },
    { id: 'KR', name: 'Korea, Republic of' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KY', name: 'Cayman Islands' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'LA', name: "Lao People's Democratic Republic" },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'LV', name: 'Latvia' },
    { id: 'LY', name: 'Libyan Arab Jamahiriya' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MD', name: 'Moldova, Republic of' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MF', name: 'Saint Martin' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MH', name: 'Marshall Islands' },
    { id: 'MK', name: 'Macedonia' },
    { id: 'ML', name: 'Mali' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'MO', name: 'Macao' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MT', name: 'Malta' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'MV', name: 'Maldives' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MX', name: 'Mexico' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NE', name: 'Niger' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NL', name: 'Netherlands' },
    { id: 'NO', name: 'Norway' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NU', name: 'Niue' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'OM', name: 'Oman' },
    { id: 'PA', name: 'Panama' },
    { id: 'PE', name: 'Peru' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PH', name: 'Philippines' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PL', name: 'Poland' },
    { id: 'PM', name: 'Saint Pierre and Miquelon' },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'PS', name: 'Palestinian Territory' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PW', name: 'Palau' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'Reunion' },
    { id: 'RO', name: 'Romania' },
    { id: 'RS', name: 'Serbia' },
    { id: 'RU', name: 'Russian Federation' },
    { id: 'RW', name: 'Rwanda' },
    { id: 'SB', name: 'Solomon Islands' },
    { id: 'SC', name: 'Seychelles' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SE', name: 'Sweden' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SH', name: 'Saint Helena' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SM', name: 'San Marino' },
    { id: 'SN', name: 'Senegal' },
    { id: 'SO', name: 'Somalia' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SS', name: 'South Sudan' },
    { id: 'ST', name: 'Sao Tome and Principe' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'SX', name: 'Sint Maarten' },
    { id: 'SY', name: 'Syrian Arab Republic' },
    { id: 'SZ', name: 'Swaziland' },
    { id: 'TC', name: 'Turks and Caicos Islands' },
    { id: 'TD', name: 'Chad' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'TG', name: 'Togo' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TT', name: 'Trinidad and Tobago' },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'TW', name: 'Taiwan' },
    { id: 'TZ', name: 'Tanzania, United Republic of' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UM', name: 'United States Minor Outlying Islands' },
    { id: 'US', name: 'United States' },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VA', name: 'Holy See (Vatican City State)' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines' },
    { id: 'VE', name: 'Venezuela' },
    { id: 'VG', name: 'Virgin Islands, British' },
    { id: 'VI', name: 'Virgin Islands, U.S.' },
    { id: 'VN', name: 'Vietnam' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'WF', name: 'Wallis and Futuna' },
    { id: 'WS', name: 'Samoa' },
    { id: 'YE', name: 'Yemen' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' },
  ]
}
export function countriesAr() {
  return [
    { id: 'المملكة العربية السعودية', name: 'المملكة العربية السعودية' },
    { id: 'أندوورا', name: 'أندورا' },
    { id: 'الإمارات العربية المتحدة', name: 'الإمارات العربية المتحدة' },
    { id: 'أفغانستان', name: 'أفغانستان' },
    { id: 'أنتيغوا وبربودا', name: 'أنتيغوا وبربودا' },
    { id: 'أنغيلا', name: 'أنغيلا' },
    { id: 'ألبانيا', name: 'ألبانيا' },
    { id: 'أرمينيا', name: 'أرمينيا' },
    { id: 'أنغولا', name: 'أنغولا' },
    { id: 'منطقة آسيا والمحيط الهادئ', name: 'منطقة آسيا والمحيط الهادئ' },
    { id: 'القارة القطبية الجنوبية', name: 'القارة القطبية الجنوبية' },
    { id: 'الأرجنتين', name: 'الأرجنتين' },
    { id: 'ساموا الأمريكية', name: 'ساموا الأمريكية' },
    { id: 'النمسا', name: 'النمسا' },
    { id: 'أستراليا', name: 'أستراليا' },
    { id: 'أروبا', name: 'أروبا' },
    { id: 'جزر', name: 'جزر آلاند' },
    { id: 'أذربيجان', name: 'أذربيجان' },
    { id: 'البوسنة والهرسك', name: 'البوسنة والهرسك' },
    { id: 'بربادوس', name: 'بربادوس' },
    { id: 'بنغلاديش', name: 'بنغلاديش' },
    { id: 'بلجيكا', name: 'بلجيكا' },
    { id: 'بوركينا فاسو', name: 'بوركينا فاسو' },
    { id: 'بلغاريا', name: 'بلغاريا' },
    { id: 'البحرين', name: 'البحرين' },
    { id: 'بوروندي', name: 'بوروندي' },
    { id: 'بنين', name: 'بنين' },
    { id: 'سانت بارتيليمي', name: 'سانت بارتيليمي' },
    { id: 'برمودا', name: 'برمودا' },
    { id: 'بروناي دار السلام', name: 'بروناي دار السلام' },
    { id: 'بوليفيا', name: 'بوليفيا' },
    { id: 'بونير وسانت يوستاتيوس وسابا', name: 'بونير وسانت يوستاتيوس وسابا' },
    { id: 'البرازيل', name: 'البرازيل' },
    { id: 'جزر البهاما', name: 'جزر البهاما' },
    { id: 'بوتان', name: 'بوتان' },
    { id: 'جزيرة بوفيت', name: 'جزيرة بوفيت' },
    { id: 'بوتسوانا', name: 'بوتسوانا' },
    { id: 'بيلاروسيا', name: 'بيلاروسيا' },
    { id: 'بليز', name: 'بليز' },
    { id: 'كندا', name: 'كندا' },
    { id: 'جزر كوكوس (كيلينغ)', name: 'جزر كوكوس (كيلينغ)' },
    { id: 'جمهورية الكونغو الديمقراطية', name: 'جمهورية الكونغو الديمقراطية' },
    { id: 'جمهورية افريقيا الوسطى', name: 'جمهورية افريقيا الوسطى' },
    { id: 'الكونغو', name: 'الكونغو' },
    { id: 'سويسرا', name: 'سويسرا' },
    { id: 'كوت ديفوار', name: 'كوت ديفوار' },
    { id: 'جزر كوك', name: 'جزر كوك' },
    { id: 'تشيلي', name: 'تشيلي' },
    { id: 'الكاميرون', name: 'الكاميرون' },
    { id: 'الصين', name: 'الصين' },
    { id: 'كولومبيا', name: 'كولومبيا' },
    { id: 'كوستا ريكا', name: 'كوستا ريكا' },
    { id: 'كوبا', name: 'كوبا' },
    { id: 'الرأس الأخضر', name: 'الرأس الأخضر' },
    { id: 'كوراكاو', name: 'كوراكاو' },
    { id: ' جزيرة الكريسماس', name: ' جزيرة الكريسماس' },
    { id: 'قبرص', name: 'قبرص' },
    { id: 'الجمهورية التشيكية', name: 'الجمهورية التشيكية' },
    { id: 'ألمانيا', name: 'ألمانيا' },
    { id: 'جيبوتي', name: 'جيبوتي' },
    { id: 'الدنمارك', name: 'الدنمارك' },
    { id: 'دومينيكا', name: 'دومينيكا' },
    { id: 'جمهورية الدومينيكان', name: 'جمهورية الدومينيكان' },
    { id: 'الجزائر', name: 'الجزائر' },
    { id: 'الاكوادور', name: 'الاكوادور' },
    { id: 'إستونيا', name: 'إستونيا' },
    { id: 'مصر', name: 'مصر' },
    { id: 'الصحراء الغربية', name: 'الصحراء الغربية' },
    { id: 'إريتريا', name: 'إريتريا' },
    { id: 'إسبانيا', name: 'إسبانيا' },
    { id: 'أثيوبيا', name: 'أثيوبيا' },
    { id: 'أوروبا', name: 'أوروبا' },
    { id: 'فنلندا', name: 'فنلندا' },
    { id: 'فيجي', name: 'فيجي' },
    { id: 'جزر فوكلاند (مالفيناس)', name: 'جزر فوكلاند (مالفيناس)' },
    { id: 'ولايات ميكرونيزيا الموحدة', name: 'ولايات ميكرونيزيا الموحدة' },
    { id: 'جزر فاروس', name: 'جزر فاروس' },
    { id: 'فرنسا', name: 'فرنسا' },
    { id: 'الجابون', name: 'الجابون' },
    { id: 'المملكة المتحدة', name: 'المملكة المتحدة' },
    { id: 'غرينادا', name: 'غرينادا' },
    { id: 'جورجيا', name: 'جورجيا' },
    { id: 'غيانا الفرنسية', name: 'غيانا الفرنسية' },
    { id: 'غيرنسي', name: 'غيرنسي' },
    { id: 'غانا', name: 'غانا' },
    { id: 'جبل طارق', name: 'جبل طارق' },
    { id: 'الأرض الخضراء', name: 'الأرض الخضراء' },
    { id: 'غامبيا', name: 'غامبيا' },
    { id: 'غينيا', name: 'غينيا' },
    { id: 'جوادلوب', name: 'جوادلوب' },
    { id: 'غينيا الإستوائية', name: 'غينيا الإستوائية' },
    { id: 'اليونان', name: 'اليونان' },
    {
      id: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
      name: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    },
    { id: 'غواتيمالا', name: 'غواتيمالا' },
    { id: 'غوام', name: 'غوام' },
    { id: 'غينيا بيساو', name: 'غينيا بيساو' },
    { id: 'غيانا', name: 'غيانا' },
    { id: 'هونج كونج', name: 'هونج كونج' },
    { id: 'قلب الجزيرة وجزر ماكدونالز', name: 'قلب الجزيرة وجزر ماكدونالز' },
    { id: 'هندوراس', name: 'هندوراس' },
    { id: 'كرواتيا', name: 'كرواتيا' },
    { id: 'هايتي', name: 'هايتي' },
    { id: 'هنغاريا', name: 'هنغاريا' },
    { id: 'إندونيسيا', name: 'إندونيسيا' },
    { id: 'أيرلندا', name: 'أيرلندا' },
    { id: 'إسرائيل', name: 'إسرائيل' },
    { id: 'جزيرة آيل أوف مان', name: 'جزيرة آيل أوف مان' },
    { id: 'الهند', name: 'الهند' },
    {
      id: 'إقليم المحيط البريطاني الهندي',
      name: 'إقليم المحيط البريطاني الهندي',
    },
    { id: 'العراق', name: 'العراق' },
    { id: 'جمهورية إيران الإسلامية', name: 'جمهورية إيران الإسلامية' },
    { id: 'أيسلندا', name: 'أيسلندا' },
    { id: 'إيطاليا', name: 'إيطاليا' },
    { id: 'جيرسي', name: 'جيرسي' },
    { id: 'جامايكا', name: 'جامايكا' },
    { id: 'الأردن', name: 'الأردن' },
    { id: 'اليابان', name: 'اليابان' },
    { id: 'كينيا', name: 'كينيا' },
    { id: 'قيرغيزستان', name: 'قيرغيزستان' },
    { id: 'كمبوديا', name: 'كمبوديا' },
    { id: 'كيريباتي', name: 'كيريباتي' },
    { id: 'جزر القمر', name: 'جزر القمر' },
    { id: 'سانت كيتس ونيفيس', name: 'سانت كيتس ونيفيس' },
    {
      id: 'كوريا، الجمهورية الشعبية الديمقراطية',
      name: 'كوريا، الجمهورية الشعبية الديمقراطية',
    },
    { id: 'جمهورية كوريا', name: 'جمهورية كوريا' },
    { id: 'الكويت', name: 'الكويت' },
    { id: 'جزر كايمان', name: 'جزر كايمان' },
    { id: 'كازاخستان', name: 'كازاخستان' },
    {
      id: 'جمهورية لاو الديمقراطية الشعبية',
      name: 'جمهورية لاو الديمقراطية الشعبية',
    },
    { id: 'لبنان', name: 'لبنان' },
    { id: 'القديسة لوسيا', name: 'القديسة لوسيا' },
    { id: 'ليختنشتاين', name: 'ليختنشتاين' },
    { id: 'سيريلانكا', name: 'سيريلانكا' },
    { id: 'ليبيريا', name: 'ليبيريا' },
    { id: 'ليسوتو', name: 'ليسوتو' },
    { id: 'ليتوانيا', name: 'ليتوانيا' },
    { id: 'لوكسمبورغ', name: 'لوكسمبورغ' },
    { id: 'لاتفيا', name: 'لاتفيا' },
    { id: 'الجماهيرية العربية الليبية', name: 'الجماهيرية العربية الليبية' },
    { id: 'المغرب', name: 'المغرب' },
    { id: 'موناكو', name: 'موناكو' },
    { id: 'جمهورية مولدوفا', name: 'جمهورية مولدوفا' },
    { id: 'الجبل الأسود', name: 'الجبل الأسود' },
    { id: 'القديس مارتن', name: 'القديس مارتن' },
    { id: 'مدغشقر', name: 'مدغشقر' },
    { id: 'جزر مارشال', name: 'جزر مارشال' },
    { id: 'مقدونيا', name: 'مقدونيا' },
    { id: 'مالي', name: 'مالي' },
    { id: 'ميانمار', name: 'ميانمار' },
    { id: 'منغوليا', name: 'منغوليا' },
    { id: 'ماكاو', name: 'ماكاو' },
    { id: 'جزر مريانا الشمالية', name: 'جزر مريانا الشمالية' },
    { id: 'مارتينيك', name: 'مارتينيك' },
    { id: 'موريتانيا', name: 'موريتانيا' },
    { id: 'مونتسيرات', name: 'مونتسيرات' },
    { id: 'مالطا', name: 'مالطا' },
    { id: 'موريشيوس', name: 'موريشيوس' },
    { id: 'جزر المالديف', name: 'جزر المالديف' },
    { id: 'ملاوي', name: 'ملاوي' },
    { id: 'المكسيك', name: 'المكسيك' },
    { id: 'ماليزيا', name: 'ماليزيا' },
    { id: 'موزمبيق', name: 'موزمبيق' },
    { id: 'ناميبيا', name: 'ناميبيا' },
    { id: 'كاليدونيا الجديدة', name: 'كاليدونيا الجديدة' },
    { id: 'النيجر', name: 'النيجر' },
    { id: 'جزيرة نورفولك', name: 'جزيرة نورفولك' },
    { id: 'نيجيريا', name: 'نيجيريا' },
    { id: 'نيكاراغوا', name: 'نيكاراغوا' },
    { id: 'هولندا', name: 'هولندا' },
    { id: 'النرويج', name: 'النرويج' },
    { id: 'نيبال', name: 'نيبال' },
    { id: 'ناورو', name: 'ناورو' },
    { id: 'نيوي', name: 'نيوي' },
    { id: 'نيوزيلاندا', name: 'نيوزيلاندا' },
    { id: 'سلطنة عمان', name: 'سلطنة عمان' },
    { id: 'بنما', name: 'بنما' },
    { id: 'بيرو', name: 'بيرو' },
    { id: 'بولينيزيا الفرنسية', name: 'بولينيزيا الفرنسية' },
    { id: 'بابوا غينيا الجديدة', name: 'بابوا غينيا الجديدة' },
    { id: 'فيلبيني', name: 'فيلبيني' },
    { id: 'باكستان', name: 'باكستان' },
    { id: 'بولندا', name: 'بولندا' },
    { id: 'سانت بيير وميكلون', name: 'سانت بيير وميكلون' },
    { id: 'بيتكيرن', name: 'بيتكيرن' },
    { id: 'بورتوريكو', name: 'بورتوريكو' },
    { id: 'فلسطين', name: 'فلسطين' },
    { id: 'البرتغال', name: 'البرتغال' },
    { id: 'بالاو', name: 'بالاو' },
    { id: 'باراغواي', name: 'باراغواي' },
    { id: 'دولة قطر', name: 'دولة قطر' },
    { id: 'ريونيون', name: 'ريونيون' },
    { id: 'رومانيا', name: 'رومانيا' },
    { id: 'صربيا', name: 'صربيا' },
    { id: 'الاتحاد الروسي', name: 'الاتحاد الروسي' },
    { id: 'رواندا', name: 'رواندا' },
    { id: 'جزر سليمان', name: 'جزر سليمان' },
    { id: 'سيشيل', name: 'سيشيل' },
    { id: 'السودان', name: 'السودان' },
    { id: 'السويد', name: 'السويد' },
    { id: 'سنغافورة', name: 'سنغافورة' },
    { id: 'سان هيلانة', name: 'سان هيلانة' },
    { id: 'سلوفينيا', name: 'سلوفينيا' },
    { id: 'سفالبارد وجان ماين', name: 'سفالبارد وجان ماين' },
    { id: 'سلوفاكيا', name: 'سلوفاكيا' },
    { id: 'سيرا ليون', name: 'سيرا ليون' },
    { id: 'سان مارينو', name: 'سان مارينو' },
    { id: 'السنغال', name: 'السنغال' },
    { id: 'الصومال', name: 'الصومال' },
    { id: 'سورينام', name: 'سورينام' },
    { id: 'جنوب السودان', name: 'جنوب السودان' },
    { id: 'ساو تومي وبرينسيبي', name: 'ساو تومي وبرينسيبي' },
    { id: 'السلفادور', name: 'السلفادور' },
    { id: 'سينت مارتن', name: 'سينت مارتن' },
    { id: 'الجمهورية العربية السورية', name: 'الجمهورية العربية السورية' },
    { id: 'سوازيلاند', name: 'سوازيلاند' },
    { id: 'جزر تركس وكايكوس', name: 'جزر تركس وكايكوس' },
    { id: 'تشاد', name: 'تشاد' },
    { id: 'المناطق الجنوبية لفرنسا', name: 'المناطق الجنوبية لفرنسا' },
    { id: 'توجو', name: 'توجو' },
    { id: 'تايلاند', name: 'تايلاند' },
    { id: 'طاجيكستان', name: 'طاجيكستان' },
    { id: 'توكيلاو', name: 'توكيلاو' },
    { id: 'تيمور ليشتي', name: 'تيمور ليشتي' },
    { id: 'تركمانستان', name: 'تركمانستان' },
    { id: 'تونس', name: 'تونس' },
    { id: 'تونغا', name: 'تونغا' },
    { id: 'تركيا', name: 'تركيا' },
    { id: 'ترينيداد وتوباغو', name: 'ترينيداد وتوباغو' },
    { id: 'توفالو', name: 'توفالو' },
    { id: 'تايوان', name: 'تايوان' },
    { id: 'جمهورية تنزانيا المتحدة', name: 'جمهورية تنزانيا المتحدة' },
    { id: 'أوكرانيا', name: 'أوكرانيا' },
    { id: 'أوغندا', name: 'أوغندا' },
    {
      id: 'جزر الولايات المتحدة البعيدة الصغرى',
      name: 'جزر الولايات المتحدة البعيدة الصغرى',
    },
    { id: 'الولايات المتحدة الأمريكية', name: 'الولايات المتحدة الأمريكية' },
    { id: 'أوروغواي', name: 'أوروغواي' },
    { id: 'أوزبكستان', name: 'أوزبكستان' },
    {
      id: 'الكرسي الرسولي (دولة الفاتيكان)',
      name: 'الكرسي الرسولي (دولة الفاتيكان)',
    },
    { id: 'سانت فنسنت وجزر غرينادين', name: 'سانت فنسنت وجزر غرينادين' },
    { id: 'فنزويلا', name: 'فنزويلا' },
    { id: 'جزر العذراء البريطانية', name: 'جزر العذراء البريطانية' },
    { id: 'جزر فيرجن الأمريكية', name: 'جزر فيرجن الأمريكية' },
    { id: 'فيتنام', name: 'فيتنام' },
    { id: 'فانواتو', name: 'فانواتو' },
    { id: 'واليس وفوتونا', name: 'واليس وفوتونا' },
    { id: 'ساموا', name: 'ساموا' },
    { id: 'اليمن', name: 'اليمن' },
    { id: 'مايوت', name: 'مايوت' },
    { id: 'جنوب أفريقيا', name: 'جنوب أفريقيا' },
    { id: 'زامبيا', name: 'زامبيا' },
    { id: 'زيمبابوي', name: 'زيمبابوي' },
  ]
}
export const NATIONALITIES = [
  { id: 'afghan', name: 'Afghan' },
  { id: 'albanian', name: 'Albanian' },
  { id: 'algerian', name: 'Algerian' },
  { id: 'american', name: 'American' },
  { id: 'andorran', name: 'Andorran' },
  { id: 'angolan', name: 'Angolan' },
  { id: 'antiguans', name: 'Antiguans' },
  { id: 'argentinean', name: 'Argentinean' },
  { id: 'armenian', name: 'Armenian' },
  { id: 'australian', name: 'Australian' },
  { id: 'austrian', name: 'Austrian' },
  { id: 'azerbaijani', name: 'Azerbaijani' },
  { id: 'bahamian', name: 'Bahamian' },
  { id: 'bahraini', name: 'Bahraini' },
  { id: 'bangladeshi', name: 'Bangladeshi' },
  { id: 'barbadian', name: 'Barbadian' },
  { id: 'barbudans', name: 'Barbudans' },
  { id: 'batswana', name: 'Batswana' },
  { id: 'belarusian', name: 'Belarusian' },
  { id: 'belgian', name: 'Belgian' },
  { id: 'belizean', name: 'Belizean' },
  { id: 'beninese', name: 'Beninese' },
  { id: 'bhutanese', name: 'Bhutanese' },
  { id: 'bolivian', name: 'Bolivian' },
  { id: 'bosnian', name: 'Bosnian' },
  { id: 'brazilian', name: 'Brazilian' },
  { id: 'british', name: 'British' },
  { id: 'bruneian', name: 'Bruneian' },
  { id: 'bulgarian', name: 'Bulgarian' },
  { id: 'burkinabe', name: 'Burkinabe' },
  { id: 'burmese', name: 'Burmese' },
  { id: 'burundian', name: 'Burundian' },
  { id: 'cambodian', name: 'Cambodian' },
  { id: 'cameroonian', name: 'Cameroonian' },
  { id: 'canadian', name: 'Canadian' },
  { id: 'cape_verdean', name: 'Cape Verdean' },
  { id: 'central_african', name: 'Central African' },
  { id: 'chadian', name: 'Chadian' },
  { id: 'chilean', name: 'Chilean' },
  { id: 'chinese', name: 'Chinese' },
  { id: 'colombian', name: 'Colombian' },
  { id: 'comoran', name: 'Comoran' },
  { id: 'congolese', name: 'Congolese' },
  { id: 'costa_rican', name: 'Costa Rican' },
  { id: 'croatian', name: 'Croatian' },
  { id: 'cuban', name: 'Cuban' },
  { id: 'cypriot', name: 'Cypriot' },
  { id: 'czech', name: 'Czech' },
  { id: 'danish', name: 'Danish' },
  { id: 'djibouti', name: 'Djibouti' },
  { id: 'dominican', name: 'Dominican' },
  { id: 'dutch', name: 'Dutch' },
  { id: 'east_timorese', name: 'East Timorese' },
  { id: 'ecuadorean', name: 'Ecuadorean' },
  { id: 'egyptian', name: 'Egyptian' },
  { id: 'emirati', name: 'Emirati' },
  { id: 'equatorial_guinean', name: 'Equatorial Guinean' },
  { id: 'eritrean', name: 'Eritrean' },
  { id: 'estonian', name: 'Estonian' },
  { id: 'ethiopian', name: 'Ethiopian' },
  { id: 'fijian', name: 'Fijian' },
  { id: 'filipinos', name: 'Filipinos' },
  { id: 'finnish', name: 'Finnish' },
  { id: 'french', name: 'French' },
  { id: 'gabonese', name: 'Gabonese' },
  { id: 'gambian', name: 'Gambian' },
  { id: 'georgian', name: 'Georgian' },
  { id: 'german', name: 'German' },
  { id: 'ghanaian', name: 'Ghanaian' },
  { id: 'greek', name: 'Greek' },
  { id: 'grenadian', name: 'Grenadian' },
  { id: 'guatemalan', name: 'Guatemalan' },
  { id: 'guinea_bissauan', name: 'Guinea Bissauan' },
  { id: 'guinean', name: 'Guinean' },
  { id: 'guyanese', name: 'Guyanese' },
  { id: 'haitian', name: 'Haitian' },
  { id: 'herzegovinian', name: 'Herzegovinian' },
  { id: 'honduran', name: 'Honduran' },
  { id: 'hungarian', name: 'Hungarian' },
  { id: 'icelander', name: 'Icelander' },
  { id: 'indian', name: 'Indian' },
  { id: 'indonesian', name: 'Indonesian' },
  { id: 'iranian', name: 'Iranian' },
  { id: 'iraqi', name: 'Iraqi' },
  { id: 'irish', name: 'Irish' },
  { id: 'israeli', name: 'Israeli' },
  { id: 'italian', name: 'Italian' },
  { id: 'ivorian', name: 'Ivorian' },
  { id: 'jamaican', name: 'Jamaican' },
  { id: 'japanese', name: 'Japanese' },
  { id: 'jordanian', name: 'Jordanian' },
  { id: 'kazakhstani', name: 'Kazakhstani' },
  { id: 'kenyan', name: 'Kenyan' },
  { id: 'kittian_and_nevisian', name: 'Kittian and Nevisian' },
  { id: 'kuwaiti', name: 'Kuwaiti' },
  { id: 'kyrgyz', name: 'Kyrgyz' },
  { id: 'laotian', name: 'Laotian' },
  { id: 'latvian', name: 'Latvian' },
  { id: 'lebanese', name: 'Lebanese' },
  { id: 'liberian', name: 'Liberian' },
  { id: 'libyan', name: 'Libyan' },
  { id: 'liechtensteiner', name: 'Liechtensteiner' },
  { id: 'lithuanian', name: 'Lithuanian' },
  { id: 'luxembourger', name: 'Luxembourger' },
  { id: 'macedonian', name: 'Macedonian' },
  { id: 'malagasy', name: 'Malagasy' },
  { id: 'malawian', name: 'Malawian' },
  { id: 'malaysian', name: 'Malaysian' },
  { id: 'maldivan', name: 'Maldivan' },
  { id: 'malian', name: 'Malian' },
  { id: 'maltese', name: 'Maltese' },
  { id: 'marshallese', name: 'Marshallese' },
  { id: 'mauritanian', name: 'Mauritanian' },
  { id: 'mauritian', name: 'Mauritian' },
  { id: 'mexican', name: 'Mexican' },
  { id: 'micronesian', name: 'Micronesian' },
  { id: 'moldovan', name: 'Moldovan' },
  { id: 'monacan', name: 'Monacan' },
  { id: 'mongolian', name: 'Mongolian' },
  { id: 'moroccan', name: 'Moroccan' },
  { id: 'mosotho', name: 'Mosotho' },
  { id: 'motswana', name: 'Motswana' },
  { id: 'mozambican', name: 'Mozambican' },
  { id: 'namibian', name: 'Namibian' },
  { id: 'nauruan', name: 'Nauruan' },
  { id: 'nepalese', name: 'Nepalese' },
  { id: 'netherlander', name: 'Netherlander' },
  { id: 'new_zealander', name: 'New Zealander' },
  { id: 'ni_vanuatu', name: 'Ni Vanuatu' },
  { id: 'nicaraguan', name: 'Nicaraguan' },
  { id: 'nigerian', name: 'Nigerian' },
  { id: 'nigerien', name: 'Nigerien' },
  { id: 'north_korean', name: 'North Korean' },
  { id: 'northern_irish', name: 'Northern Irish' },
  { id: 'norwegian', name: 'Norwegian' },
  { id: 'omani', name: 'Omani' },
  { id: 'pakistani', name: 'Pakistani' },
  { id: 'palauan', name: 'Palauan' },
  { id: 'palestinian', name: 'Palestinian' },
  { id: 'panamanian', name: 'Panamanian' },
  { id: 'papua_new_guinean', name: 'Papua New Guinean' },
  { id: 'paraguayan', name: 'Paraguayan' },
  { id: 'peruvian', name: 'Peruvian' },
  { id: 'polish', name: 'Polish' },
  { id: 'portuguese', name: 'Portuguese' },
  { id: 'qatari', name: 'Qatari' },
  { id: 'romanian', name: 'Romanian' },
  { id: 'russian', name: 'Russian' },
  { id: 'rwandan', name: 'Rwandan' },
  { id: 'saudi', name: 'Saudi' },
  { id: 'saint_lucian', name: 'Saint Lucian' },
  { id: 'salvadoran', name: 'Salvadoran' },
  { id: 'samoan', name: 'Samoan' },
  { id: 'san_marinese', name: 'San Marinese' },
  { id: 'sao_tomean', name: 'Sao Tomean' },
  { id: 'scottish', name: 'Scottish' },
  { id: 'senegalese', name: 'Senegalese' },
  { id: 'serbian', name: 'Serbian' },
  { id: 'seychellois', name: 'Seychellois' },
  { id: 'sierra_leonean', name: 'Sierra Leonean' },
  { id: 'singaporean', name: 'Singaporean' },
  { id: 'slovakian', name: 'Slovakian' },
  { id: 'slovenian', name: 'Slovenian' },
  { id: 'solomon_islander', name: 'Solomon Islander' },
  { id: 'somali', name: 'Somali' },
  { id: 'south_african', name: 'South African' },
  { id: 'south_korean', name: 'South Korean' },
  { id: 'spanish', name: 'Spanish' },
  { id: 'sri_lankan', name: 'Sri Lankan' },
  { id: 'sudanese', name: 'Sudanese' },
  { id: 'surinamer', name: 'Surinamer' },
  { id: 'swazi', name: 'Swazi' },
  { id: 'swedish', name: 'Swedish' },
  { id: 'swiss', name: 'Swiss' },
  { id: 'syrian', name: 'Syrian' },
  { id: 'taiwanese', name: 'Taiwanese' },
  { id: 'tajik', name: 'Tajik' },
  { id: 'tanzanian', name: 'Tanzanian' },
  { id: 'thai', name: 'Thai' },
  { id: 'togolese', name: 'Togolese' },
  { id: 'tongan', name: 'Tongan' },
  { id: 'trinidadian_or_tobagonian', name: 'Trinidadian or Tobagonian' },
  { id: 'tunisian', name: 'Tunisian' },
  { id: 'turkish', name: 'Turkish' },
  { id: 'tuvaluan', name: 'Tuvaluan' },
  { id: 'ugandan', name: 'Ugandan' },
  { id: 'ukrainian', name: 'Ukrainian' },
  { id: 'uruguayan', name: 'Uruguayan' },
  { id: 'uzbekistani', name: 'Uzbekistani' },
  { id: 'venezuelan', name: 'Venezuelan' },
  { id: 'vietnamese', name: 'Vietnamese' },
  { id: 'welsh', name: 'Welsh' },
  { id: 'yemeni', name: 'Yemeni' },
  { id: 'zambian', name: 'Zambian' },
  { id: 'zimbabwean', name: 'Zimbabwean' },
]
export function nationalities() {
  return NATIONALITIES
}

export function banks() {
  return [
    { id: 'the_national_commercial_bank', name: 'The National Commercial Ban' },
    { id: 'the_saudi_british_bank', name: 'The Saudi British Bank' },
    { id: 'saudi_investment_bank', name: 'Saudi Investment Bank' },
    { id: 'alinma_bank', name: 'Alinma Bank' },
    { id: 'banque_saudi_fransi', name: 'Banque Saudi Fransi' },
    { id: 'riyad_bank', name: 'Riyad Bank' },
    {
      id: 'samba_financial_group_samba',
      name: 'Samba Financial Group (Samba)',
    },
    { id: 'saudi_hollandi_bank', name: 'Saudi Hollandi Bank' },
    { id: 'al_rajhib_bank', name: 'Al Rajhi Bank' },
    { id: 'arab_national_bank', name: 'Arab National Bank' },
    { id: 'bank_albilad', name: 'Bank AlBilad' },
    { id: 'bank_aljazira', name: 'Bank AlJazira' },
  ]
}

export const roles = [
  { id: 'sales_person', name: 'Sales Person' },
  { id: 'sales_manager', name: 'Sales Manager' },
  { id: 'finance_person', name: 'Finance Person' },
  { id: 'finance_manager', name: 'Finance Manager' },
  { id: 'admin', name: 'Admin' },
]

export const permissions = {
  sales_person: ['setting', 'lead_close_deal', 'payment_collection'],
  sales_manager: ['setting', 'lead_close_deal', 'payment_collection'],
  finance_person: ['setting'],
  finance_manager: [],
  admin: [],
}

export const PAYMENT_METHODS = [
  { id: 'bank_transfer_payment', name: 'Bank Transfer Payment' },
  { id: 'cash_payment', name: 'Cash Payment' },
  { id: 'check_payment', name: 'Check Payment' },
  { id: 'mada_machine', name: 'Mada Machine' },
]

export const AvailableTimes = [
  { name: '08-10', id: '08-10' },
  { name: '10-12', id: '10-12' },
  { name: '12-14', id: '12-14' },
  { name: '14-16', id: '14-16' },
  { name: '16-18', id: '16-18' },
  { name: '18-20', id: '18-20' },
]
export const ExpectedArrival = [
  { name: '6AM - 10AM', id: '1' },
  { name: '10AM - 02PM', id: '2' },
  { name: '02PM - 06PM', id: '3' },
  { name: '06PM - 10PM', id: '4' },
  { name: '10PM - 12AM', id: '5' },
]

export const adminStatuses = [
  { name: 'Pending', id: 'pending' },
  { name: 'Assigned', id: 'assigned' },
  { name: 'In Progress', id: 'in_progress' },
  { name: 'Resolved', id: 'resolved' },
  { name: 'Cancelled', id: 'cancelled' },
  { name: 'Closed', id: 'closed' },
]

export const providerStatuses = [
  { name: 'Pending', id: 'pending' },
  { name: 'In Progress', id: 'in_progress' },
  { name: 'Resolved', id: 'resolved' },
]

export const priorities = [
  { name: 'Low', id: 'low' },
  { name: 'Normal', id: 'normal' },
  { name: 'High', id: 'high' },
  { name: 'Critical', id: 'critical' },
]

export function bankAccountNumber(
  banks,
  bank_id,
  change,
  bane_name,
  account_number,
) {
  if (banks && banks.length > 0 && bank_id) {
    const g = banks.find(b => b.id === bank_id)
    if (g) {
      setTimeout(function() {
        change(bane_name, g.bank_name)
        change(account_number, g.bank_account_number)
      }, 100)
    }
  }

  // if(value){
  //   return value
  // }

  return false
}

export const COMPOUND_TYPES = [
  { name: 'Residential', value: 'residential' },
  { name: 'Commercial', value: 'commercial' },
]

export const UNIT_CATEGORIES = [
  { name: 'Residential', id: 'residential' },
  { name: 'Commercial', id: 'commercial' },
  { name: 'General', id: 'general' },
]

export const RELATION_TO_TENANT = [
  { name: 'Family', value: '1' },
  { name: 'Friends', value: '0' },
]
export const VISIT_TYPE = [
  { name: 'Group', value: '1' },
  { name: 'Single', value: '0' },
]

export const UNIT_PRICE_TYPES = [
  { name: 'Fixed Price', id: 'fixed_price' },
  { name: 'Range Price', id: 'range_price' },
]

export const PART_CATEGORYIES_TYPES = [
  { name: 'Additional Cost', value: 'additional_cost' },
  { name: 'Spare Part', value: 'part' },
]

export const TASK_STATUSES = [
  { name: 'Open', value: 'open' },
  { name: 'In Progress', value: 'in_progress' },
  { name: 'On Hold', value: 'on_hold' },
  { name: 'Complete', value: 'complete' },
]

export const AREA_EQUIPMENTS_TYPES = [
  { name: 'Common Area', value: 'common_area' },
  { name: 'Equipment', value: 'equipment' },
]

export const REQUEST_TYPES = [
  { name: 'Specific Unit', value: 'unit' },
  { name: 'Common Area', value: 'common_area' },
  { name: 'Equipment', value: 'equipment' },
]

export const TENANT_ACCESSES_TYPES = [
  { name: 'Tenant', value: 'tenant' },
  { name: 'Contracts', value: 'quotation' },
  { name: 'Units', value: 'unit' },
]
export const OWNER_ACCESSES_TYPES = [
  { name: 'Owner', value: 'unit_owner' },
  /*,{ name: 'Units', value: 'unit' },*/
]

export const PREFERRED_LANGUAGE = [
  { name: 'English', value: 'en' },
  { name: 'Arabic', value: 'ar' },
  /*,{ name: 'Units', value: 'unit' },*/
]

export const VAT_PERCENT = [
  { name: '0%', value: '0.0' },
  { name: '15%', value: '15.0' },
]

export const QUOTATION_TYPES = [
  { value: 'fixed', label: 'Fixed' },
  { value: 'incremental', label: 'Incremental' },
]

export const ID_TYPES = [
  { value: 'saudi_no', label: 'Saudi No.' },
  { value: 'iqama_no', label: 'Iqama No.' },
  { value: 'passport_no', label: 'Passport No.' },
]

export const CONTRACTING_ID_TYPES = [
  { value: 'sagia_license', label: 'Sagia License' },
  { value: 'commercial_register', label: 'Commercial Register' },
]

export const CONTRACT_TYPES = [
  { value: 'new', label: 'New' },
  { value: 'renewal', label: 'Renewal' },
]

export const TEMPLATE_NATURES = [
  { value: 'residential', label: 'Residential' },
  { value: 'commercial', label: 'Commercial' },
]
export const STATUS_COLORS = {
  requests_approved: 'green',
  approved: 'green',
  requests_rejected: 'red',
  rejected: 'red',
  waiting_for_approval: 'yellow',
  cancelled: 'gray',
}
